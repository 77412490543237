<template>
    <div class="container">
        <header class="jumbotron">
            <h3>{{this.mode === 'edit' ? 'Edytuj' : 'Dodaj'}} role</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
                <label for="name">Nazwa</label>
                <InputText id="name" type="text" v-model="content.name" placeholder="Wpisz nazwę roli"/>
            </div>
        </div>

        <DataTable :value="permissions" v-model:selection="selectedPermissions" dataKey="id" :loading="loading" removableSort responsiveLayout="scroll">
            <template #header>
            </template>
            <template #empty>
                Nie znalieziono uprawnień
            </template>
            <template #loading>
                Ładwanie uprawnień. Proszę czekać
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
            <Column field="description" header="Uprawnienie" :sortable="true"></Column>
<!--            <Column field="key" header="Kod" :sortable="true"></Column>-->
        </DataTable>

        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
            <Button type="button" label="Zapisz" v-on:click="click"/>
        </div>
    </div>
</template>

<script>
    import RolesService from "../../services/roles.service";
    import PermissionsService from "../../services/permisions.service";

    export default {
        inject: ['global'],
        name: "AddEditRole",
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    name: "",
                    permissions: [],
                }
            },
        },
        data() {
            return {
                selectedPermissions: [],
                permissions: '',
                content: this.initialCntent,
            };
        },
        methods: {
            click: function () {
                this.content.permissions = this.selectedPermissions.map(permission => ( permission.id ));
                if (this.mode === 'edit') {
                    RolesService.editRole(this.content, this.$route.params.roleId).then(
                        () => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sukces',
                                detail: 'Poprawnie zapisano role',
                                life: 3000
                            });
                            this.$router.push({path: `${this.global.state.instancePatch}/role/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    RolesService.addRole(this.content).then(
                        () => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sukces',
                                detail: 'Poprawnie zapisano role',
                                life: 3000
                            });
                            this.$router.push({path: `${this.global.state.instancePatch}/role/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
            goBack: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/role/lista`});
            }
        },
        mounted() {
            this.mode = this.$route.params.roleId ? 'edit' : 'add';
            if (this.mode === 'edit') {
                RolesService.getRole(this.$route.params.roleId).then(
                    (response) => {
                        this.content = response.data;
                        this.content.id = undefined;
                        this.content.createdAt = undefined;
                        this.content.updatedAt = undefined;
                        this.selectedPermissions = this.content.permissions;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
            PermissionsService.getAllPermissions().then(
                (response) => {
                    this.permissions = response.data;
                },
                (error) => {
                    this.permissions =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    };
</script>
