import api from './api';

const API_URL = `/permissions`;

class PermissionsService {

    getAllPermissions() {
        return api.get(API_URL, {
            params: {
                limit: 100,
                page: 1
            }
        });
    }

}

export default new PermissionsService();
